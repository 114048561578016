import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import client from 'apollo/apolloClient';
import I18nextProvider from 'providers/I18nextProvider';

import GlobalStyle from './globalStyle';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');

if (!container) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <I18nextProvider>
        <App />
      </I18nextProvider>
      {/* @ts-ignore */}
      <GlobalStyle />
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
