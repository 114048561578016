import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.less';

import Loader from 'components/Loader';
import ErrorBoundary from './components/Layouts/ErrorBoundary';
import AuthProvider from './providers/AuthProvider';
import UserProvider from './providers/UserProvider';
import AntConfigProvider from './providers/AntdConfigProvider';
import AccountProvider from './providers/AccountProvider';
import MyI18nextProvider from './providers/I18nextProvider';
import Router from './router';

const App = () => {
  return (
    <Suspense
      fallback={<Loader webSize={400} mobileSize={25} loaderName="preLoader" />}
    >
      <AntConfigProvider>
        <BrowserRouter>
          <MyI18nextProvider>
            <AuthProvider>
              <UserProvider>
                <AccountProvider>
                  <ErrorBoundary>
                    <Router />
                  </ErrorBoundary>
                </AccountProvider>
              </UserProvider>
            </AuthProvider>
          </MyI18nextProvider>
        </BrowserRouter>
      </AntConfigProvider>
    </Suspense>
  );
};

export default App;
