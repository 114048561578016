import Styled from 'styled-components';
import { Dropdown } from 'antd';

export const StyledHeaderWrapper = Styled.div`
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;
      overflow: hidden;
      background-color: #f0f2f5 !important;
      z-index: 2;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;

      .ant-typography {
        cursor: pointer;
      }

      .ant-menu-inline-collapsed-tooltip a,
      .ant-menu-inline-collapsed-tooltip a:hover {
         color: #000 !important;
      }

      .ant-dropdown-placement-bottomCenter {
          top: 50px;
        }

      @media (max-width: 768px) {
        && {
          z-index: 1000;
          padding: 0 20px;
        }
      }
        }
`;

export const StyledHeaderContent = Styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    .user-dropdown {
        cursor: pointer;
    }

    .search-icon {
        font-size: 16px;
        margin-right: 10px;
        cursor: pointer;
    }

    .avatar-divider {
        height: 24px;
        margin-right: 24px;
        @media (max-width: 768px) {
          margin-right: 16px;
          margin-left: 4px;
        }
    }
`;

export const StyledDropdownWrapper = Styled(Dropdown)`
  width: 336px;
  max-height: 446px;
  border-radius: 4px;
  padding: 0 !important;

  ul {
      padding-inline-start: 0 !important;
      margin: 0;
    }

    .ant-dropdown-menu {
      padding: 0;
    }

    .ant-list-item-meta-avatar {
      align-self: center;
      font-size: 20px;
    }

  .list_item {
    padding: 24px;
    cursor: pointer;

    .ant-progress-line {
        width: 40%;
    }

    &:hover {
      background-color: #4da7892d;
    }
  }
`;

export const StyledIconWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 48px;
  cursor: pointer;
`;

export const StyledMenuWrapper = Styled.div`
    flex-grow: 2;

    .menu-item {
        margin: 0 !important;
        padding-left: 58px;
        font-weight: 400;
    }

    .submenu-item {
        margin: 0 !important;
        font-weight: 400;

        @media (max-width: 768px) {
            .ant-menu-submenu-arrow {
                right: 24px;
            }
        }
    }
`;
export const StyledUserWrapper = Styled.div``;

export const StyledLogoWrapper = Styled.div`
  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;
