import axios, { AxiosError } from 'axios';

import { getFieldsDataFromServerError } from '../utils';
import { LocalStorage } from 'utils/localStorageServices';

const axiosApi = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_SSO_URL}/`
});

axiosApi.interceptors.request.use(config => {
  const token = LocalStorage.getAuthToken() || '';

  const headers: { [key: string]: string } = {
    Authorization: `Bearer ${token}`
  };

  // @ts-ignore
  Object.assign(config.headers, headers);

  return config;
});

axiosApi.interceptors.response.use(
  response => response,
  (error: AxiosError<any>) => {
    const { response } = error;
    const errorData = response?.data;

    return new Promise((_, reject) => {
      if (errorData) {
        // @ts-ignore
        const { errors: errors1 } = errorData;

        if (errors1) {
          const errors = getFieldsDataFromServerError(errors1);

          // @ts-ignore
          return reject({ ...errorData, errors });
        }

        return reject(errorData);
      }

      return reject(error);
    });
  }
);

export default axiosApi;
