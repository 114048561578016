import { Workspace } from 'apollo/types';
import { NavigateFunction } from 'react-router-dom';

const { REACT_APP_SSO_URL } = process.env;
interface ServerError {
  [key: string]: string;
}

type FieldsData = {
  name: string;
  errors: string;
};

export const getFieldsDataFromServerError = (errors: ServerError = {}) => {
  const error: FieldsData[] = Object.keys(errors).reduce(
    (acc, key) => acc.concat({ name: key, errors: errors[key] }),
    [] as FieldsData[]
  );

  return error;
};

export const navigateToWorkspaceCreation = () => {
  const { REACT_APP_SSO_URL } = process.env;
  const onboardingUrl = `${REACT_APP_SSO_URL}/callback?&target=onboarding-workspace`;
  window.location.href = onboardingUrl;
};

export const getHost = () => {
  const remoteHost = window.location.host;

  // if (
  //   process.env.NODE_ENV === 'development' &&
  //   process.env.REACT_APP_REMOTE_HOST_HEADER_OVERRIDE
  // ) {
  //   remoteHost = process.env.REACT_APP_REMOTE_HOST_HEADER_OVERRIDE;
  // }

  return remoteHost;
};

export const getCurrentWorkspaceNameFromHost = () => {
  const { REACT_APP_PARTNER_HOST } = process.env;
  const remoteHost = getHost();

  // remove the base domain, from the URL, it is the workspacename
  const sliceupto = remoteHost.indexOf(REACT_APP_PARTNER_HOST as string) - 1;

  return sliceupto < 0 ? '' : remoteHost.slice(0, sliceupto);
};

export const getWorkspaceSubDomainWithNativeHost = (
  workspaceAlias: string | null = null
) => {
  const { REACT_APP_PARTNER_HOST } = process.env;
  workspaceAlias = workspaceAlias ?? getCurrentWorkspaceNameFromHost();

  return workspaceAlias + '.' + REACT_APP_PARTNER_HOST;
};

export const getWorkspaceDomainUrl = (
  workspaceAlias: string | null = null,
  withScheme: boolean | null = true,
  subDomainOfWorkspace: string | null = null
) => {
  const { REACT_APP_PARTNER_HOST } = process.env;

  // create subdomain for workspace
  workspaceAlias = workspaceAlias ?? getCurrentWorkspaceNameFromHost();

  let workspaceUrl = `${workspaceAlias}.${REACT_APP_PARTNER_HOST}`;

  if (subDomainOfWorkspace) {
    workspaceUrl = `${subDomainOfWorkspace}.${workspaceUrl}`;
  }

  if (withScheme) {
    const httpScheme =
      process.env.NODE_ENV === 'development' ? 'http://' : 'https://';

    workspaceUrl = httpScheme + workspaceUrl;
  }

  return workspaceUrl;
};

export const getWorkspaceDashboardUrl = () => {
  return getWorkspaceDomainUrl(null, true, 'dashboard');
};

export const navigateToWorkspaceURL = (
  path: string,
  workspace: Workspace | null,
  navigate: NavigateFunction | null,
  navigateOptions: object | undefined = {}
) => {
  //do we need to change the workspace URL
  const currentHost = getWorkspaceDomainUrl();
  const { REACT_APP_SSO_URL } = process.env;

  if (workspace) {
    const newHost = getWorkspaceDomainUrl(workspace.name);

    if (currentHost !== newHost) {
      window.location.href = `${REACT_APP_SSO_URL}/callback?workspaceId=${workspace.id}&returnUrl=${path}&target=partners`;

      return false;
    }
  }

  if (navigate) {
    navigate(path, navigateOptions);

    return false;
  } else {
    const url = `${currentHost}${path}`;
    window.location.href = url;
  }
};

export const passwordRegexp =
  /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]{8,128}$/;

export const redirectToProjectDashboard = (projectId: string) => {
  const redirectingUrl = `${REACT_APP_SSO_URL}/callback?target=dashboard&projectId=${projectId}`;
  window.open(redirectingUrl, '_blank', 'noopener,noreferrer');
};
