import { UploadFile } from 'antd/lib/upload/interface';

export const pageTypes = {
  BASIC_PROFILE_PAGE: 'BASIC_PROFILE_PAGE',
  BILLING_INFO_PAGE: 'BILLING_INFO_PAGE',
  SECURITY_PAGE: 'SECURITY_PAGE',
  ADVANCED_SETTINGS_PAGE: 'ADVANCED_SETTINGS_PAGE'
};

export const sectionTypes = {
  BASICPROFILE: 'basicProfile',
  BILLINGINFO: 'billingInfo',
  SECURITY: 'security'
} as const;

export const ActionTypes = {
  SET_PAGE: 'SET_PAGE',
  SET_BASIC_DATA: 'SET_BASIC_DATA',
  SET_BILLING_DATA: 'SET_BILLING_DATA',
  SET_SECURITY_DATA: 'SET_SECURITY_DATA',
  SET_ADVANCED_DATA: 'SET_ADVANCED_DATA',
  DISCARD_DATA: 'DISCARD_DATA',
  RESET_AVATAR_DATA: 'RESET_AVATAR_DATA',
  SET_HOORY: 'SET_HOORY'
} as const;

export type Action =
  | { type: typeof ActionTypes.SET_PAGE; payload: string }
  | { type: typeof ActionTypes.SET_BASIC_DATA; payload: object }
  | { type: typeof ActionTypes.SET_BILLING_DATA; payload: object }
  | { type: typeof ActionTypes.DISCARD_DATA; payload: string }
  | { type: typeof ActionTypes.RESET_AVATAR_DATA; payload: boolean }
  | { type: typeof ActionTypes.SET_HOORY; payload: boolean }
  | { type: typeof ActionTypes.SET_ADVANCED_DATA };

export type Dispatch = (action: Action) => void;

export type IBasicProfile = {
  firstName: string;
  lastName: string;
  language: string;
  receiveNewsletter: boolean;
  avatarUrl?: string | undefined;
  avatar?: UploadFile | undefined;
};

export type IUpdateProfileType = {
  updateProfile: IBasicProfile;
};

export type INewsLetter = { receiveNewsletter: boolean | undefined };

export type IBillingInfo = {
  fullName: string;
  firstName?: string;
  lastName?: string;
  country: string;
  city: string;
  street: string;
  zipCode: string | number;
  phoneNumber: string | number;
  state: string | number;
};

export type ISecurity = {
  email?: string;
  oldPassword?: string | null;
  password?: string | null;
};

export type IAdvancedSettings = {
  data?: [] | null;
};

export type AccountProviderState = {
  page: string;
  isFetching: boolean;
  basicProfile: IBasicProfile;
  billingInfo: IBillingInfo;
  security: ISecurity;
  advancedSettings: IAdvancedSettings;
  resetAvatar: boolean;
  hoory: boolean;
};
