import { gql } from '@apollo/client';

export const getWorkspacesQuery = gql`
  query workspaces(
    $first: Int
    $page: Int
    $orderBy: [WorkspacesOrderByClause!]
    $search: String
  ) {
    workspaces(
      input: { page: $page, first: $first, orderBy: $orderBy, search: $search }
    ) {
      data {
        id
        name
        clientName
        logoUrl
        legal
        invoiceSuffix
        invoicePrefix
        invoiceStartNumber
        projectsCount
        address
        webPage
        userRole {
          isAdminType
          isOwnerType
        }
        businessLegalName
        businessDescription
        creator {
          firstName
          lastName
        }
        usersCount
        url
        nativeDomainHost
      }
      paginatorInfo {
        count
        hasMorePages
        total
        perPage
      }
    }
  }
`;

export const deleteWorkspaceMutation = gql`
  mutation RemoveWorkspace($id: ID!, $password: String!) {
    removeWorkspace(input: { id: $id, password: $password })
  }
`;

export const updateWorkspaceMutation = gql`
  mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      id
      address
      businessLegalName
      webPage
      businessDescription
      logo
      legal
    }
  }
`;

export const createWorkspaceMutation = gql`
  mutation CreateWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      name
      id
    }
  }
`;

export const GetWorkspaceByName = gql`
  query WorkspaceByName($name: String!) {
    workspaceByName(name: $name) {
      id
    }
  }
`;
