import React, { FC } from 'react';
import { Layout } from 'antd';

import { StyledMobileLayoutWrapper } from './styles';

interface Props {
  children: React.ReactElement;
}

const MobileLayout: FC<Props> = ({ children }) => (
  <StyledMobileLayoutWrapper>
    <Layout.Content>{children}</Layout.Content>
  </StyledMobileLayoutWrapper>
);

export default MobileLayout;
