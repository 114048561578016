import { ComponentType, lazy } from 'react';

function retry(
  fn: Function,
  retriesLeft = 5,
  interval = 1000
): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);

            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default function lazyImport(fn: Function) {
  return lazy(() => retry(fn));
}
