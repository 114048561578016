import { FC } from 'react';
import Lottie from 'react-lottie';
import { isMobileDevice } from 'apollo/cache';
import { useReactiveVar } from '@apollo/client';

import styles from './styles.module.less';
import { LoaderProps } from './types';
import { LoaderView } from './constants';

const Loader: FC<LoaderProps> = ({
  webSize = 50,
  mobileSize = 25,
  loaderName = 'preLoader'
}) => {
  const isMobile = useReactiveVar(isMobileDevice);

  const defaultOptions = {
    loop: true,
    renderer: 'svg',
    autoplay: true,
    animationData: LoaderView[loaderName],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={styles.loader_wrapper}>
      <Lottie
        options={defaultOptions}
        height={isMobile ? mobileSize : webSize}
        width={isMobile ? mobileSize : webSize}
        isClickToPauseDisabled={true}
      />
    </div>
  );
};

export default Loader;
