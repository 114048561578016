import { Collapse, CollapsePanelProps } from 'antd';
import { FC } from 'react';

export type UcPanelProps = CollapsePanelProps;

const BasePanel: FC<UcPanelProps> = props => {
  const { children } = props;

  return <Collapse.Panel {...props}>{children}</Collapse.Panel>;
};

const UcPanel = Object.assign(BasePanel, Collapse.Panel);

export default UcPanel;
