import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Result } from 'antd';

import ProtectedRoute from 'components/Layouts/enhancers/protectedRoute';
import {
  HomePage,
  ProjectsList,
  EditProject,
  ClientsList,
  ClientDetails,
  // AppsList,
  AddApp,
  TeamsList,
  AddEditMember,
  SettingsApp,
  MyAccountsApp,
  TemplatesList,
  AddTemplate,
  EditTemplate,
  MyWorkspaces,
  AppComponents,
  SignInPage,
  SignUpPage,
  ForbiddenPage
} from './lazyImports';

// Protected Routes
const ProjectsListProtected = ProtectedRoute(ProjectsList, true);
const EditProjectProtected = ProtectedRoute(EditProject, true);
const ClientsListProtected = ProtectedRoute(ClientsList, true);
const ClientDetailsProtected = ProtectedRoute(ClientDetails, true);
// const AppsListProtected = ProtectedRoute(AppsList, true);
const AddAppProtected = ProtectedRoute(AddApp, true);
const TeamsListProtected = ProtectedRoute(TeamsList, true);
const AddEditMemberProtected = ProtectedRoute(AddEditMember, true);
const SettingsAppProtected = ProtectedRoute(SettingsApp, true);
const TemplatesListProtected = ProtectedRoute(TemplatesList, true);
const AddTemplateProtected = ProtectedRoute(AddTemplate, true);
const EditTemplateProtected = ProtectedRoute(EditTemplate, true);
const MyAccountsAppProtected = ProtectedRoute(MyAccountsApp, true, false);
const MyWorkspacesProtected = ProtectedRoute(MyWorkspaces, true, false);
const AppComponentsProtected = ProtectedRoute(AppComponents, true);

interface Props {
  path: string;
  element: ReactNode | any;
  children?: Props[];
  exact?: boolean;
  title: string;
}

export const routes: Props[] = [
  {
    path: '/',
    title: 'Home',
    element: <HomePage />
  },
  {
    path: 'projects',
    title: 'projects',
    exact: true,
    element: <Outlet />,
    children: [
      {
        path: '',
        title: 'projects',
        exact: true,
        element: <ProjectsListProtected />
      },
      {
        path: 'edit/:id',
        title: 'projects-edit',
        exact: true,
        element: <EditProjectProtected />
      }
    ]
  },
  {
    path: 'clients',
    title: 'clients',
    exact: true,
    element: <Outlet />,
    children: [
      {
        path: '',
        title: 'clients',
        exact: true,
        element: <ClientsListProtected />
      },
      {
        path: 'details/:id',
        title: 'client-details',
        exact: true,
        element: <ClientDetailsProtected />
      }
    ]
  },
  {
    path: 'apps',
    title: 'apps',
    exact: true,
    element: <Outlet />,
    children: [
      // {
      //   path: '',
      //   title: 'apps',
      //   exact: true,
      //   element: <AppsListProtected />
      // },
      {
        path: 'components/:id',
        title: 'apps component',
        exact: true,
        element: <AppComponentsProtected />
      },
      {
        path: 'add',
        title: 'apps-add',
        exact: true,
        element: <AddAppProtected />
      },
      {
        path: 'edit/:id',
        title: 'apps-add',
        exact: true,
        element: <AddAppProtected />
      },
      {
        path: 'error',
        title: 'apps-error',
        exact: true,
        element: <Result status={404} title="Page Not Found" />
      }
    ]
  },
  {
    path: 'teams',
    title: 'teams',
    exact: true,
    element: <Outlet />,
    children: [
      {
        path: '',
        title: 'teams',
        exact: true,
        element: <TeamsListProtected />
      },
      {
        path: 'add',
        title: 'teams-add',
        exact: true,
        element: <AddEditMemberProtected />
      },
      {
        path: 'edit/:id',
        title: 'teams-edit',
        exact: true,
        element: <AddEditMemberProtected />
      }
    ]
  },
  {
    path: 'templates',
    title: 'templates',
    exact: true,
    element: <Outlet />,
    children: [
      {
        path: '',
        title: 'template',
        exact: true,
        element: <TemplatesListProtected />
      },
      {
        path: 'add',
        title: 'template-add',
        exact: true,
        element: <AddTemplateProtected />
      },
      {
        path: 'edit/:id',
        title: 'template-add',
        exact: true,
        element: <EditTemplateProtected />
      }
    ]
  },
  {
    path: 'settings',
    title: 'settings',
    exact: true,
    element: <SettingsAppProtected />
  },

  {
    path: 'my-accounts',
    title: 'my-accounts',
    exact: true,
    element: <MyAccountsAppProtected />
  },
  {
    path: 'my-workspaces',
    title: 'my-workspaces',
    exact: true,
    element: <MyWorkspacesProtected />
  },
  {
    path: 'sign-up',
    title: 'sign-up',
    exact: true,
    element: <SignUpPage />
  },
  {
    path: 'sign-in',
    title: 'sign-in',
    exact: true,
    element: <SignInPage />
  },
  {
    path: 'forbidden',
    title: 'forbidden',
    exact: true,
    element: <ForbiddenPage />
  },
  {
    path: '*',
    title: '404',
    element: <Result status={404} title="Page Not Found" />
  }
];
