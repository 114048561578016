import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message, Typography, Space } from 'antd';
import axios from 'axios';
import VerificationImage from 'assets/svg/verification.svg';

import useInterval from 'hooks/useInterval';
import { Button, Input, Modal } from 'components/basic';
import { StyledVerificationModalWrapper } from './styles';

const { Title, Text } = Typography;

const { REACT_APP_SSO_URL } = process.env;

const verificationUrl = `${REACT_APP_SSO_URL}/email/verification-notification`;

const AccountVerification = () => {
  const [resendLoading, setResendLoading] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);

  const { t } = useTranslation('common');

  const { startTimer, timeout, isPlaying } = useInterval();

  useEffect(() => {
    setModalVisibility(true);
  }, []);

  const resendInvitation = async () => {
    try {
      setResendLoading(true);
      const res = await axios({
        method: 'POST',
        url: verificationUrl,
        withCredentials: true
      });

      message.success(res.data.message);
      startTimer();
    } catch (error) {
      console.error(error);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Modal
      className="verificationModal"
      width={457}
      centered
      visible={modalVisibility}
      footer={null}
      closable={false}
    >
      <StyledVerificationModalWrapper>
        <img src={VerificationImage} alt="verification" />
        <Space direction="vertical" align="center">
          <Title level={3}>{t('accountVerification')}</Title>
          <Text>{t('verificationDescription')}</Text>
        </Space>
        <Button
          disabled={isPlaying}
          className="resend"
          onClick={resendInvitation}
          loading={resendLoading}
          type="primary"
        >
          {t('resend')}
        </Button>
        {isPlaying && (
          <>
            <Form.Item
              style={{
                marginBottom: 0,
                marginTop: '20px'
              }}
              label="Resend in:"
            >
              <Input disabled value={timeout} autoComplete="off" />
            </Form.Item>
          </>
        )}
      </StyledVerificationModalWrapper>
    </Modal>
  );
};

export default AccountVerification;
