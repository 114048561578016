import React, { useState, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Avatar, Layout, List, Skeleton, Typography, Tooltip } from 'antd';
import {
  UserOutlined,
  SettingOutlined,
  FolderAddOutlined,
  CaretRightOutlined,
  CaretLeftOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import {
  workspacesListVar,
  currentWorkspaceVar,
  workspacePaginatorInfo
} from 'apollo/cache';
import { Workspace } from 'apollo/types';
import { useWorkspaces } from 'providers/WorkspaceProvider';
import { ReactComponent as TemplateOutlined } from 'assets/icons/TemplateOutlined.svg';
import { ReactComponent as TeamsOutlined } from 'assets/icons/TeamsOutlined.svg';
import {
  getCurrentWorkspaceNameFromHost,
  navigateToWorkspaceCreation,
  navigateToWorkspaceURL
} from 'utils';
import type { MenuProps as AntdMenuProps } from 'antd';

import { Button, Collapse } from 'components/basic';
import Header from 'components/GlobalHeader';
import SiderMenu from 'components/GlobalHeader/SiderMenu';
import { MenuProps } from 'components/GlobalHeader/menuTypes';
import MobileLayout from 'components/Layouts/MobileLayout';
import {
  StyledLayoutWrapper,
  StyledWorkspaceListWrapper,
  StyledWorkspaceItem,
  StyledSidebarButtonWrapper
} from './styles';

const { Text } = Typography;

const { Sider, Content } = Layout;
const { Panel } = Collapse;

type MenuItem = Required<AntdMenuProps>['items'][number];

interface LayoutProps {
  user?: any;
  sidebar?: boolean;
  children: React.ReactElement;
  topRightMenu?: MenuItem[];
}

const AdminLayout: FC<LayoutProps> = ({
  children,
  user,
  sidebar,
  topRightMenu
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [paginatorInfo] = useState({
    hasMorePages: false,
    perPage: 1
  });

  const workspaceName = getCurrentWorkspaceNameFromHost();
  const workspacesList = useReactiveVar(workspacesListVar);
  const currentWorkspace = useReactiveVar(currentWorkspaceVar);
  const { data, fetchMore, loading } = useWorkspaces();

  const navigate = useNavigate();
  const { t } = useTranslation('workspaces');
  const location = useLocation();
  const getMenuItems = useMemo(
    () =>
      [
        {
          key: 'projects',
          icon: <FolderAddOutlined />,
          title: t('Projects'),
          url: 'projects'
        },
        // {
        //   key: 'apps',
        //   icon: <AppstoreOutlined />,
        //   title: t('Apps'),
        //   url: 'apps'
        // },
        {
          key: 'templates',
          icon: <TemplateOutlined />,
          title: t('Templates'),
          url: 'templates'
        },
        {
          key: 'clients',
          icon: <UserOutlined />,
          title: t('Clients'),
          url: 'clients'
        },
        {
          key: 'teams',
          icon: <TeamsOutlined />,
          title: t('Teams'),
          url: 'teams'
        },
        {
          key: 'settings',
          icon: <SettingOutlined />,
          title: t('Settings'),
          url: 'settings'
        }
      ] as MenuProps[],
    []
  );

  useTranslation('menu');

  const projectNameSplitted = getCurrentWorkspaceNameFromHost().split(' ');

  const isProductsPage = useLocation().pathname === `/products/products`;

  const SettingsPath = location.pathname.split('/');
  const isSettingsPage = SettingsPath[SettingsPath.length - 2] === 'settings';

  const toggle = () => {
    setCollapsed(!collapsed);

    if (!collapsed) {
      setActiveKeys([]);
    }
  };

  const GetCurrentWorkspaceName = () => {
    if (currentWorkspace) {
      const workspaceNameStr = currentWorkspace?.name;

      return (
        workspaceNameStr?.charAt(0).toUpperCase() + workspaceNameStr?.slice(1)
      );
    } else {
      return t('workplace');
    }
  };

  const handleChangeWorkspace = (item: Workspace) => {
    currentWorkspaceVar(item);
    navigateToWorkspaceURL('/projects', item, navigate);
    toggle();
  };

  useEffect(() => {
    if (!loading && data?.workspaces) {
      workspacesListVar(data.workspaces.data);
      workspacePaginatorInfo(data.workspaces.paginatorInfo);
    }
  }, [loading]);

  useEffect(() => {
    if (workspacesList?.length && !currentWorkspace) {
      const getWorkspace = workspacesList.filter(
        item => item.name === workspaceName
      )[0];

      currentWorkspaceVar(getWorkspace || workspacesList[0]);
    }
  }, [workspacesList]);

  useEffect(() => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    }

    const resize = () => {
      if (window.innerWidth < 992) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }

      if (window.innerWidth < 769) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        offset: paginatorInfo.perPage * 10
      }
    });
  };

  if (isProductsPage) {
    return <MobileLayout>{children}</MobileLayout>;
  }

  return (
    <>
      <StyledLayoutWrapper>
        {!isMobile && sidebar && (
          <Sider
            trigger={
              collapsed ? (
                <CaretRightOutlined data-test="sider-trigger" />
              ) : (
                <CaretLeftOutlined data-test="sider-trigger" />
              )
            }
            collapsible
            collapsed={collapsed}
            onCollapse={toggle}
            className="page-sider"
            width={220}
            breakpoint="xs"
            collapsedWidth={isMobile ? 0 : 80}
          >
            <Collapse
              className={`side-collapse ${collapsed ? 'side-collapse' : ''}`}
              activeKey={activeKeys}
              ghost
              expandIconPosition="end"
              onChange={actKeys => {
                if (collapsed) {
                  setCollapsed(false);
                }

                setActiveKeys(actKeys as SetStateAction<string[]>);
              }}
            >
              <Panel
                key={1}
                header={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: collapsed ? 'center' : 'stretch',
                      alignItems: 'center'
                    }}
                    data-test="project-list-opeant-collapse-arrowner"
                  >
                    <Avatar shape="square" size={32}>
                      {currentWorkspace?.name?.charAt(0)}
                    </Avatar>
                    {!collapsed && (
                      <Text
                        title="Ucraft-Partners"
                        style={{
                          marginLeft: 8,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: 130
                        }}
                      >
                        {GetCurrentWorkspaceName()}
                      </Text>
                    )}
                  </div>
                }
              >
                <StyledWorkspaceListWrapper id="scrollableDiv">
                  <InfiniteScroll
                    dataLength={workspacesList?.length}
                    next={handleFetchMore}
                    hasMore={paginatorInfo.hasMorePages}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      dataSource={workspacesList}
                      renderItem={(item: any) => (
                        <List.Item key={item.id}>
                          <div onClick={() => handleChangeWorkspace(item)}>
                            <StyledWorkspaceItem>
                              <Avatar size="default" shape="square">
                                {item.name.substring(0, 2).toUpperCase()}
                              </Avatar>
                              {item.clientName.length > 13 ? (
                                <Tooltip
                                  placement="rightBottom"
                                  title={item.clientName}
                                >
                                  <span className="workspace-name">
                                    {item.clientName}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span className="workspace-name">
                                  {item.clientName}
                                </span>
                              )}
                            </StyledWorkspaceItem>
                          </div>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </StyledWorkspaceListWrapper>
                <StyledSidebarButtonWrapper>
                  <Button
                    type="default"
                    onClick={() =>
                      navigateToWorkspaceURL('/my-workspaces', null, navigate)
                    }
                  >
                    {t('allWorkspaces')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => navigateToWorkspaceCreation()}
                  >
                    {t('create')}
                  </Button>
                </StyledSidebarButtonWrapper>
              </Panel>
            </Collapse>
            <SiderMenu menus={workspacesList?.length ? getMenuItems : []} />
          </Sider>
        )}
        <Layout
          style={{
            marginLeft: sidebar ? 220 : 0,
            marginTop: 43
          }}
          className={collapsed ? 'layout-collapsed' : 'layout-large'}
        >
          <Header
            user={user}
            isMobile={isMobile}
            menus={getMenuItems}
            currentProject={projectNameSplitted.join(' ')}
            topRightMenu={topRightMenu}
          />
          <Content
            className={isSettingsPage ? 'site-layout' : 'site-layout-general'}
          >
            {children}
          </Content>
        </Layout>
      </StyledLayoutWrapper>
    </>
  );
};

export default AdminLayout;
