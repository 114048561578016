import React, { FC } from 'react';
import { Select, SelectProps } from 'antd';

import UcSelectOption from './Option';

type BaseSelectProps = SelectProps<any>;

const BaseSelect: FC<BaseSelectProps> = props => <Select {...props} />;

const UcSelect = Object.assign(Select, BaseSelect, {
  option: UcSelectOption
});

export default UcSelect;
