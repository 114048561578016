import Styled from 'styled-components';

export const StyledNavbarWrapper = Styled.nav`
  background-color: #fff;
  padding-left: 4px;
`;

export const StyledNavbarTitle = Styled.div`
  margin: 0 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  svg {
    font-size: 10px;
    margin-left: 5px;
  }
`;

export const StyledAddNew = Styled.div`
   display: flex;
   justify-content: center;
`;
