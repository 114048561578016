import Styled from 'styled-components';
import { Layout } from 'antd';

export const StyledLayoutWrapper = Styled(Layout)`
   min-height: 100vh;

  .anticon-caret-right,
  .anticon-caret-left {
    opacity: 0.5;
  }

  .ant-layout-sider-trigger,
  .ant-layout-sider-zero-width-trigger {
    position: absolute;
    right: -15px;
    bottom: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px !important;
    height: 32px;
    margin: auto;
    color: black;
    background: #fff;
    border-radius: 0 4px 4px 0;
  }

  .ant-layout-sider-zero-width-trigger {
    top: unset;
  }

  .anticon {
    margin: 0;
  }

  .page-sider {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 3;
    height: 100vh;
    padding-bottom: 0 !important;
    background: #f0f2f5 !important;

    .ant-layout-sider-children {
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.03),
        0 6px 16px rgba(0, 0, 0, 0.05), 0 3px 6px -4px rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      :nth-child(2) {
        flex-grow: 2;
      }
    }

    .ant-layout-sider-trigger {
      z-index: -1;
    }

    .ant-menu{
      background-color:  #f0f2f5;
      .ant-menu-item{
        margin: 0 15px;
        display: flex;
        align-items: center;
        span{
          display: inline-block;
        }
         .ant-menu-item-icon{
            width: 14px;
          }
        :hover{
          .ant-menu-item-icon{
             path, circle, ellipse{
                stroke: #06C654;
            }
             .teams-path{
              fill: #06C654;
              background: #06C654;
              stroke: #06C654;
            }

         }
        }
      }
      .ant-menu-item-selected{
        background-color: #fff !important;
        border-radius: 8px;
        font-size: 14px;
       .ant-menu-item-icon{
            path, circle, ellipse{
                stroke: #06C654;
            }
            .teams-path{
              fill: #06C654;
              background: #06C654;
              stroke: #06C654;
            }
       }
      }
    }
  }

  .side-collapse {
    width: 100%;

    ul {
      height: calc(100% - 60px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .ant-collapse-item-active {
        height: 100vh;
    }

    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .layout-collapsed {min-height: 100vh;

  .ant-layout-sider-trigger,
  .ant-layout-sider-zero-width-trigger {
    position: absolute;
    right: -15px;
    bottom: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px !important;
    height: 32px;
    margin: auto;
    color: black;
    background: #fff;
    border-radius: 0 4px 4px 0;
  }

  .ant-layout-sider-zero-width-trigger {
    top: unset;
  }

  .anticon {
    margin: 0;
  }

  .page-sider {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 3;
    height: 100vh;
    padding-bottom: 0 !important;
    background: #fff !important;

    .ant-layout-sider-children {
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.03),
        0 6px 16px rgba(0, 0, 0, 0.05), 0 3px 6px -4px rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      :nth-child(2) {
        flex-grow: 2;
      }
    }

    .ant-layout-sider-trigger {
      z-index: -1;
    }
  }

  .side-collapse {
    width: 100%;

    ul {
      height: calc(100% - 60px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .ant-collapse-item-active {
        height: 100vh;
    }

    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .layout-collapsed {
    margin-left: 80px !important;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  .layout-large {
    margin-left: 220px !important;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

    margin-left: 80px !important;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  .layout-large {
    margin-left: 220px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  .ant-avatar {
    > span {
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .site-layout-general {
    padding: 24px;
    padding-bottom: 0;
    min-height: 280px;
  }
`;

export const StyledIconWrapper = Styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledSettingIcon = Styled.div`
  svg {
    margin-right: 10px;
  }
`;

export const StyledManageWebsiteWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  background: #fff;

  button {
    width: 100%;
  }

  .no-collapsed-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-typography {
      color: #fff;
    }
  }
`;

export const StyledWorkspaceListWrapper = Styled.div`
  padding: 0;
  height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: auto;

  .workspace-name {
    white-space: nowrap;
    display: inline-block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }

  ul {
    padding: 0;
    margin: 0;
    height: 100% !important;

    li {
      list-style-type: none;
      font-size: 14px;
      padding: 16px 24px;
      border-top: 1px solid #f0f0f0;
      cursor: pointer;
    }
  }
`;
export const StyledWorkspaceItem = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  span {
    margin-right: 8px;
    font-weight: 700;

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const StyledSidebarButtonWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 20px;
  .ant-btn {
      margin-left: 0 !important;
      margin-top: 10px;
  }
  button {
    width: 100%;
  }
`;
