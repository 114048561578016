import React, { useEffect, useMemo, useState, FC } from 'react';
import { Avatar, Drawer, List, Skeleton, Typography } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  currentWorkspaceVar,
  workspacePaginatorInfo,
  workspacesListVar
} from 'apollo/cache';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Workspace } from 'apollo/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { getWorkspacesQuery } from 'graphql/partner/workspaces';
import { navigateToWorkspaceURL } from 'utils';

import { StyledNavbarWrapper, StyledNavbarTitle } from './style';
import {
  StyledWorkspaceItem,
  StyledWorkspaceListWrapper
} from '../Layouts/AdminLayout/styles';

const { Text } = Typography;

interface Props {
  currentProject: string;
  visible: boolean;
  setVisible: (data: boolean) => void;
}

const ProjectsNavBar: FC<Props> = ({ currentProject, visible, setVisible }) => {
  const [paginatorInfo] = useState({
    hasMorePages: false,
    perPage: 1
  });

  const { t } = useTranslation(['common', 'projects']);
  const currentWorkspace = useReactiveVar(currentWorkspaceVar);

  const isSmall = useMemo(() => window.innerWidth < 379, [window.innerWidth]);
  const { data, fetchMore, loading } = useQuery(getWorkspacesQuery, {
    variables: {
      offset: 0,
      limit: 10
    }
  });

  const navigate = useNavigate();
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const pathName = pathArray[pathArray.length - 1];

  const workspacesList = useReactiveVar(workspacesListVar);

  useEffect(() => {
    if (!loading && data?.workspaces) {
      workspacesListVar(data.workspaces.data);
      workspacePaginatorInfo(data.workspaces.paginatorInfo);
    }
  }, [loading]);

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        offset: paginatorInfo.perPage * 10
      }
    });
  };

  const handleChangeWorkspace = (item: Workspace) => {
    currentWorkspaceVar(item);
    navigateToWorkspaceURL(`/${pathName}`, item, navigate);
  };

  return (
    <StyledNavbarWrapper>
      <StyledNavbarTitle onClick={() => setVisible(!visible)}>
        <Text title={currentProject}>{currentProject}</Text>
        {!visible ? <DownOutlined /> : <UpOutlined />}
      </StyledNavbarTitle>
      <Drawer
        contentWrapperStyle={{ width: isSmall ? 320 : 378 }}
        height="100%"
        placement="top"
        headerStyle={{ paddingLeft: 20 }}
        bodyStyle={{ padding: '0' }}
        closeIcon={<></>}
        onClose={() => setVisible(false)}
        open={visible}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'stretch',
            alignItems: 'center',
            padding: '10px'
          }}
        >
          <Avatar shape="square" size={32}>
            P
          </Avatar>
          <Text
            title={t('common:ucraftPartners')}
            style={{
              marginLeft: 8,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 130
            }}
          >
            {currentWorkspace?.name}
          </Text>
        </div>
        <StyledWorkspaceListWrapper id="scrollableDiv">
          <InfiniteScroll
            dataLength={workspacesList?.length}
            next={handleFetchMore}
            hasMore={paginatorInfo.hasMorePages}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={workspacesList}
              renderItem={(item: any) => (
                <List.Item key={item.name}>
                  <div onClick={() => handleChangeWorkspace(item)}>
                    <StyledWorkspaceItem>
                      <Avatar size="default" shape="square">
                        {item.name.substring(0, 2).toUpperCase()}
                      </Avatar>
                      <span>{item.clientName}</span>
                    </StyledWorkspaceItem>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </StyledWorkspaceListWrapper>
      </Drawer>
    </StyledNavbarWrapper>
  );
};

export default ProjectsNavBar;
