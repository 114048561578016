import { useQuery } from '@apollo/client';
import { getWorkspacesQuery } from 'graphql/partner/workspaces';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export interface WorkspaceContextState {
  data: any;
  refetch: () => void;
  fetchMore: any;
  loading: boolean;
}

const WorkspaceContext = React.createContext<WorkspaceContextState | undefined>(
  undefined
);

export function WorkspaceProvider({ children }: Props) {
  const workspacesQuery = useQuery(getWorkspacesQuery, {
    variables: {
      offset: 0,
      limit: 10,
      first: 100
    }
  });

  return (
    <WorkspaceContext.Provider value={{ ...workspacesQuery }}>
      {children}
    </WorkspaceContext.Provider>
  );
}

export function useWorkspaces() {
  const context = React.useContext(WorkspaceContext);

  if (typeof context === 'undefined') {
    throw new Error('useWorkspace must be used within a WorkspaceProvider');
  }

  return context;
}
