import styled from 'styled-components';

export const StyledVerificationModalWrapper = styled.div`
  text-align: center;

  h3 {
    margin-top: 20px;
  }

  .resend {
    margin-top: 20px;
  }

  .verificationModal {
    .ant-modal-body {
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .ant-space-item {
      text-align: center;
    }

    .ant-input {
      width: 50px;
      text-align: center;

      &.ant-input-disabled {
        color: #434343;
        cursor: default;
      }
    }
  }
`;
