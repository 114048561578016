import React from 'react';
import { Table, TableProps } from 'antd';

interface Props extends TableProps<object> {
  pagination?: false | object | undefined;
  columns: Array<object>;
  dataSource: Array<object>;
  showSorterTooltip: boolean;
}

const BaseTable = ({
  pagination,
  columns,
  dataSource,
  showSorterTooltip,
  ...rest
}: Props) => (
  <Table
    pagination={pagination}
    columns={columns}
    dataSource={dataSource}
    showSorterTooltip={showSorterTooltip}
    {...rest}
  />
);

export default BaseTable;
