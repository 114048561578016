import { AccountProviderState } from './types';

export default {
  page: 'BASIC_PROFILE_PAGE',
  isFetching: false,
  basicProfile: {},
  billingInfo: {},
  security: {},
  advancedSettings: {},
  resetAvatar: false,
  hoory: false
} as AccountProviderState;
