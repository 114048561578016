import { AccountProviderState, Action, ActionTypes, pageTypes } from './types';

const reducer = (state: AccountProviderState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case ActionTypes.SET_BASIC_DATA:
      return {
        ...state,
        basicProfile: { ...state.basicProfile, ...action.payload }
      };
    case ActionTypes.SET_BILLING_DATA:
      return {
        ...state,
        billingInfo: { ...state.billingInfo, ...action.payload }
      };
    case ActionTypes.RESET_AVATAR_DATA:
      return {
        ...state,
        resetAvatar: action.payload
      };
    case ActionTypes.DISCARD_DATA:
      let actionName = '';

      if (action.payload === pageTypes.BASIC_PROFILE_PAGE) {
        actionName = 'basicProfile';
      }

      if (action.payload === pageTypes.BILLING_INFO_PAGE) {
        actionName = 'billingInfo';
      }

      return actionName
        ? {
            ...state,
            [actionName]: {}
          }
        : state;
    case ActionTypes.SET_HOORY:
      return {
        ...state,
        hoory: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
