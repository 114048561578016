export function setupIcons() {
  const lightSchemeIcon = document.querySelector('link#light-scheme-icon');
  const darkSchemeIcon = document.querySelector('link#dark-scheme-icon');

  function setLight() {
    document.head.append(lightSchemeIcon as Node);
    darkSchemeIcon?.remove();
  }

  function setDark() {
    lightSchemeIcon?.remove();
    document.head.append(darkSchemeIcon as Node);
  }

  const matcher = window.matchMedia('(prefers-color-scheme:dark)');

  function onUpdate() {
    if (matcher.matches) {
      setLight();
    } else {
      setDark();
    }
  }

  matcher.addListener(onUpdate);
  onUpdate();
}
