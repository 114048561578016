import React, { FC } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

type Props = ModalProps;

const BaseModal: FC<Props> = props => <Modal {...props} />;

const UcModal = Object.assign(Modal, BaseModal);

export default UcModal;
