import { FC, createContext, useCallback, useContext, useReducer } from 'react';
import Api from 'axiosApi';

import SpinWrapper from '../../components/SpinWrapper';
import { LocalStorage } from '../../utils/localStorageServices';
import initialState from './initialState';
import reducer from './reducer';
import { Dispatch, State } from './types';

type Props = {
  children: React.ReactNode;
};

const UserStateContext = createContext<State | undefined>(undefined);
const UserDispatchContext = createContext<Dispatch | undefined>(undefined);

const UserProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { isFetching } = state.user;

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {!isFetching ? children : <SpinWrapper />}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

function useUserStateContext() {
  const context = useContext(UserStateContext);

  if (typeof context === 'undefined') {
    throw new Error('useUserStateContext must be used within a UserProvider');
  }

  return context;
}

function useUserDispatchContext() {
  const dispatch = useContext(UserDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useUserDispatchContext must be used within a UserProvider'
    );
  }

  // TODO: define correct type
  const SignUpAction = useCallback(async (params: any) => {
    const data = await Api.post('/register', params);

    return data;
  }, []);

  const PartnerSignUpAction = useCallback(async (params: any) => {
    const data = await Api.post('/api/partner-register', params);

    return data;
  }, []);

  // TODO: define correct type
  const SignInAction = useCallback(async (params: any) => {
    const { data } = await Api.post('/login', params);
    const access_token = data?.accessToken;

    if (access_token) {
      LocalStorage.setAuthToken(access_token);
    }

    return data;
  }, []);

  return {
    SignUpAction,
    PartnerSignUpAction,
    SignInAction
  };
}

export default UserProvider;
export { useUserStateContext, useUserDispatchContext };
