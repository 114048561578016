import { ApolloClient } from '@apollo/client';

import { cache } from './cache';
import { link } from './link';

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  }
};

const client = new ApolloClient({
  cache,
  link,
  defaultOptions
});

export default client;
