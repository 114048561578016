import { Input } from 'antd';
import { SearchProps } from 'antd/es/input';

const { Search } = Input;

const BaseSearch = (props: SearchProps) => (
  <Search autoComplete="off" {...props} />
);

const UcSearch = Object.assign(Search, BaseSearch);

export default UcSearch;
