import React, {
  useReducer,
  createContext,
  useContext,
  useCallback,
  FC
} from 'react';
import { useMutation } from '@apollo/client';
import i18n from 'i18next';
import { message } from 'antd';
import {
  createBillingDetailsMutation,
  updateProfileMutation,
  updateBillingDetailsMutation,
  updatePasswordMutation
} from 'graphql/partner/myAccount';

import initialState from './initialState';
import reducer from './reducer';
import {
  Dispatch,
  AccountProviderState,
  ActionTypes,
  IBasicProfile,
  IBillingInfo,
  INewsLetter
} from './types';
import SpinWrapper from '../../components/SpinWrapper';

interface Props {
  children: React.ReactNode;
}

export type SecurityType = {
  oldPassword: string;
  password: string;
};

const AccountStateContext = createContext<AccountProviderState | undefined>(
  undefined
);

const AccountDispatchContext = createContext<Dispatch | undefined>(undefined);

const AccountProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { isFetching } = state;

  return (
    <AccountStateContext.Provider value={state}>
      <AccountDispatchContext.Provider value={dispatch}>
        {!isFetching ? children : <SpinWrapper />}
      </AccountDispatchContext.Provider>
    </AccountStateContext.Provider>
  );
};

function useAccountStateContext() {
  const context = useContext(AccountStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useAccountStateContext must be used within a AccountProvider'
    );
  }

  return context;
}

function useAccountDispatchContext() {
  const dispatch = useContext(AccountDispatchContext);
  const [updateProfile] = useMutation(updateProfileMutation);
  const [createBillingDetails] = useMutation(createBillingDetailsMutation);
  const [updateBillingDetails] = useMutation(updateBillingDetailsMutation);
  const [updatePassword] = useMutation(updatePasswordMutation, {
    context: {
      hideMessages: true
    }
  });

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useAccountDispatchContext must be used within a AccountProvider'
    );
  }

  const setBasicData = useCallback(
    async (
      data:
        | IBasicProfile
        | { [key: string]: string | boolean | File | Blob | undefined }
    ) => {
      if (data?.avatar) {
        // reducing image quality before uploading to the server
        // const resizedImage: unknown = await imageResizer(data?.avatar);

        dispatch({
          type: ActionTypes.SET_BASIC_DATA,
          payload: { avatar: data?.avatar }
        });
      } else {
        dispatch({ type: ActionTypes.SET_BASIC_DATA, payload: data });
      }
    },
    [dispatch]
  );

  const setBillingData = useCallback(
    async (data: object) => {
      dispatch({ type: ActionTypes.SET_BILLING_DATA, payload: data });
    },
    [dispatch]
  );

  const discardAccountData = useCallback(
    async (data: string) => {
      dispatch({ type: ActionTypes.DISCARD_DATA, payload: data });
    },
    [dispatch]
  );

  const resetAvatarData = useCallback(
    async (data: boolean) => {
      dispatch({ type: ActionTypes.RESET_AVATAR_DATA, payload: data });
    },
    [dispatch]
  );

  const passwordData = useCallback(async (params: SecurityType) => {
    try {
      await updatePassword({
        variables: {
          input: { ...params }
        }
      });
    } catch (e: any) {
      if (e?.message === 'Password is incorrect.') {
        message.error(i18n.t<string>('errors:incorrectOldPassword'));
      } else {
        message.error(i18n.t<string>('errors:serverInternalError'));
      }
    }
  }, []);

  const userBasicData = useCallback(
    async (params: IBasicProfile | INewsLetter) => {
      try {
        const response = await updateProfile({
          variables: {
            input: { ...params }
          }
        });

        if (response?.data?.updateProfile) {
          return response?.data?.updateProfile;
        }
      } catch (e) {
        message.error(i18n.t<string>('errors:serverInternalError'));
      }
    },
    []
  );

  const createBillingData = useCallback(async (params: IBillingInfo) => {
    try {
      const response = await createBillingDetails({
        variables: {
          input: { ...params }
        }
      });

      if (response?.data?.createBillingDetails) {
        message.success(i18n.t<string>('settings:billing.billingUpdated'));
      }
    } catch (e) {
      message.error(i18n.t<string>('errors:serverInternalError'));
    }
  }, []);

  const updateBillingData = useCallback(async (params: IBillingInfo) => {
    try {
      const response = await updateBillingDetails({
        variables: {
          input: { ...params }
        }
      });

      if (response?.data?.updateBillingDetails) {
        message.success(i18n.t<string>('settings:billing.billingUpdated'));
      }
    } catch (e) {
      message.error(i18n.t<string>('errors:serverInternalError'));
    }
  }, []);

  return {
    userBasicData,
    passwordData,
    setBillingData,
    createBillingData,
    updateBillingData,
    discardAccountData,
    setBasicData,
    resetAvatarData
  };
}

export default AccountProvider;
export { useAccountStateContext, useAccountDispatchContext };
