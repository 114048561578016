import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { currentUser, currentWorkspaceVar } from 'apollo/cache';
import { Col, Row } from 'antd';
import { WorkspaceProvider } from 'providers/WorkspaceProvider';
import { useAuthState } from 'providers/AuthProvider';
import { getCurrentWorkspaceNameFromHost, navigateToWorkspaceURL } from 'utils';
import { useNavigate } from 'react-router-dom';
import { GetWorkspaceByName } from 'graphql/partner/workspaces';

import { LocalStorage } from 'utils/localStorageServices';
import Loader from 'components/Loader';
import AdminLayoutWrapper from '../AdminLayout/wrapper';
import AccountVerification from 'components/AccountVerification';
import { useQuery } from 'hooks/useQuery';

const { REACT_APP_SSO_URL, REACT_APP_ACCOUNTS_URL } = process.env;

export default function protectedRoute(
  Component: FC<{ user?: any }>,
  isProjectPage = false,
  hasSidebar = true
) {
  return () => {
    const query = useQuery();
    const currUser = useReactiveVar(currentUser);
    const token = LocalStorage.getAuthToken() || '';
    const nonce_token = query.get('nonce');

    const navigate = useNavigate();

    const currentWorkspace = useReactiveVar(currentWorkspaceVar);

    const { setAuthenticated, isAuthenticated } = useAuthState();
    const [loading, setLoading] = useState(true);

    const hostname = window.location.hostname.split('.');
    const [getWorkspacebyName] = useLazyQuery(GetWorkspaceByName);

    useEffect(() => {
      if (!isAuthenticated) {
        return;
      }

      const onLoad = async () => {
        try {
          const user = await axios({
            method: 'GET',
            url: `${REACT_APP_SSO_URL}/api/user`,
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          currentUser(user.data.data);
          setAuthenticated(true);
          setLoading(false);
        } catch (error: any) {
          setAuthenticated(false);
          location.href = '/sign-in';
        }
      };

      if (!localStorage.uc_token && !nonce_token) {
        location.href = '/sign-in';
      }

      if (!currentUser() && token) {
        onLoad();
      }
    }, [token, isAuthenticated]);

    useEffect(() => {
      setLoading(false);
    }, [currUser]);

    useEffect(() => {
      const workspaceName = getCurrentWorkspaceNameFromHost();

      if (workspaceName && isAuthenticated) {
        getWorkspacebyName({
          variables: {
            name: workspaceName
          }
        });
      }
    }, [isAuthenticated]);

    useEffect(() => {
      const workspaceName = getCurrentWorkspaceNameFromHost();

      if (
        !workspaceName &&
        currentWorkspace &&
        location.pathname !== '/my-workspaces'
      ) {
        navigateToWorkspaceURL(location.pathname, currentWorkspace, navigate);
      }
    }, [hostname, currentWorkspace]);

    if (currUser && !currUser?.isPartner) {
      location.href = `${REACT_APP_ACCOUNTS_URL}/partner-confirm`;
    }

    if (currUser && !currUser.emailVerifiedAt) {
      return <AccountVerification />;
    }

    return currUser && !loading ? (
      isProjectPage ? (
        <WorkspaceProvider>
          <AdminLayoutWrapper user={currUser} sidebar={hasSidebar}>
            <Component />
          </AdminLayoutWrapper>
        </WorkspaceProvider>
      ) : (
        <WorkspaceProvider>
          <Component user={currUser} />
        </WorkspaceProvider>
      )
    ) : (
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col>
          <Loader webSize={400} mobileSize={25} loaderName="preLoader" />
        </Col>
      </Row>
    );
  };
}
