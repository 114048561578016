import { State } from './types';

export default {
  token: '',
  user: {
    isFetching: false,
    data: null
  },
  verifyModalVisible: true,
  email: ''
} as State;
