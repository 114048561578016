import { FC, useLayoutEffect } from 'react';
import { ConfigProvider, Modal } from 'antd';
import { Locale } from 'antd/lib/locale-provider';
import { useTranslation } from 'react-i18next';
import en from 'antd/lib/locale/en_US';
import hy from 'antd/lib/locale/hy_AM';

import { setupIcons } from '../utils/favicons-setup';

const locales: Record<string, Locale> = { en, hy };

Modal.defaultProps = {
  ...Modal.defaultProps,
  maskClosable: false
};

interface Props {
  children: JSX.Element;
}

const AntConfigProvider: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const locale = locales[i18n.language] || en;

  useLayoutEffect(() => {
    setupIcons();
  }, []);

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};

export default AntConfigProvider;
