import React from 'react';
import { Row, Col } from 'antd';

import Loading from './Loading';

const SpinWrapper = () => (
  <Row justify="center" align="middle" style={{ height: '100vh' }}>
    <Col>
      <Loading />
    </Col>
  </Row>
);

export default SpinWrapper;
