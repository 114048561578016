export type BillingType = {
  fullName?: string;
  country?: string;
  city?: string;
  street?: string;
  zipCode?: string | number;
  phoneNumber?: string | number;
  phoneCode?: string | number;
  state?: string | number;
};

export type IUserData = {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  avatarUrl?: string | null;
  emailVerifiedAt: boolean | null;
  isPartner: boolean | null;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
  billingDetails: BillingType;
  businessInfo: Array<any>;
  projectsCount: number;
  params: {
    receiveNewsletter: boolean;
    language: string;
  };
};

export const ActionTypes = {
  SET_USER_FETCHING: 'SET_USER_FETCHING',
  SET_USER_DATA: 'SET_USER_DATA',
  USER_LOGOUT: 'USER_LOGOUT',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  UPDATE_USER_BILLING_DATA: 'UPDATE_USER_BILLING_DATA',
  REMOVE_USER_AVATAR: 'REMOVE_USER_AVATAR'
} as const;

interface SetUserData {
  type: typeof ActionTypes.SET_USER_DATA;
  data: IUserData;
}

export interface IUpdatedUserData {
  avatarUrl: string;
  firstName: string;
  language: string;
  lastName: string;
  receiveNewsletter: boolean;
}

export type Action =
  | SetUserData
  | { type: typeof ActionTypes.SET_USER_FETCHING; isFetching: boolean }
  | { type: typeof ActionTypes.USER_LOGOUT }
  | { type: typeof ActionTypes.UPDATE_USER_BILLING_DATA; payload: object }
  | { type: typeof ActionTypes.REMOVE_USER_AVATAR }
  | { type: typeof ActionTypes.UPDATE_USER_DATA; payload: IUpdatedUserData };

export type State = {
  token: string | null;
  user: {
    isFetching: boolean;
    data: IUserData | null;
  };
  verifyModalVisible: boolean;
  email: string;
};

export type Dispatch = (action: Action) => void;

export type SocialProps = {
  provider: string;
  accessToken: string;
};
