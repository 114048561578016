// @ts-nocheck
/* eslint-disable camelcase */
import { FC } from 'react';
import fetch from 'cross-fetch';
import { ApolloLink, from, HttpLink, makeVar } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import i18n from 'i18next';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { message as antMessage } from 'antd';
import { getWorkspaceSubDomainWithNativeHost } from 'utils';

import { LocalStorage } from 'utils/localStorageServices';

const {
  REACT_APP_COMMERCE_URL,
  REACT_APP_BUILDER_URL,
  REACT_APP_SSO_URL,
  REACT_APP_SSO_URL_V2
} = process.env;

const commerce_uri = `${REACT_APP_COMMERCE_URL}/graphql`;
const builder_uri = `${REACT_APP_BUILDER_URL}/graphql`;
const accounts_uri = `${REACT_APP_SSO_URL}/graphql`;
const accounts_v2_uri = `${REACT_APP_SSO_URL_V2}/graphql`;

export const permission = makeVar<number[]>([]);

type OptionType = {
  onProgress: (event: ProgressEvent) => void;
  onAbortPossible: Function;
  useUpload?: boolean;
  // eslint-disable-next-line no-undef
} & RequestInit;

const uploadFetch: FC<Props> = (
  url: string,
  options: OptionType
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      // eslint-disable-next-line no-undef
      const opts: ResponseInit = {
        status: xhr.status,
        statusText: xhr.statusText
      };

      const body =
        'response' in xhr ? xhr.response : (xhr as XMLHttpRequest).responseText;

      resolve(new Response(body, opts));
    };

    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.open(options.method as string, url, true);

    Object.keys(options.headers as Headers).forEach(key => {
      xhr.setRequestHeader(
        key,
        (options.headers as Headers)[key as keyof typeof options.headers]
      );
    });

    xhr.upload.onprogress = options.onProgress;

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body as any);
  });
};

function customFetch(uri: string, options: OptionType): Promise<Response> {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }

  return fetch(uri, options);
}

const links = {
  namedLink: () =>
    new ApolloLink((operation, forward) => {
      const contextUrl = operation.getContext().urlType;

      let currentUri = '';

      /**
       * This context will be passed to the mutation or query like this:
       * context: \{ urlType: linkTypes.commerce \} without the backslash '\'
       */
      switch (contextUrl) {
        case linkTypes.builder:
          currentUri = builder_uri;
          break;
        case linkTypes.commerce:
          currentUri = commerce_uri;
          break;
        case linkTypes.accountsV2:
          currentUri = accounts_v2_uri;
          break;
        default:
          currentUri = accounts_uri;
      }

      if (process.env.NODE_ENV === 'development') {
        const operationUrl = operation.operationName
          ? `${currentUri}?${operation.operationName}`
          : currentUri;

        operation.setContext(() => ({
          uri: operationUrl
        }));
      }

      return forward ? forward(operation) : null;
    }),

  auth: () =>
    new ApolloLink((operation, forward) => {
      const token = LocalStorage.getAuthToken() || '';
      const remoteHost = getWorkspaceSubDomainWithNativeHost();

      operation.setContext(() => ({
        headers: {
          'Remote-Host': remoteHost,
          authorization: `Bearer ${token}`,
          ...(operation.getContext().locale
            ? { 'x-current-language-code': operation.getContext().locale }
            : {})
        }
      }));

      if (
        operation.getContext().permissionId &&
        !permission().includes(operation.getContext().permissionId)
      ) {
        return null;
      }

      return forward(operation)?.map(data => {
        if (operation.query.definitions[0].operation) {
          if (
            operation.query.definitions[0]?.operation === 'mutation' &&
            !data.errors?.length
          ) {
            const context = operation.getContext();

            if (!context.hideMessages) {
              /* eslint-disable-next-line no-console */
              console.log({
                'operation name': operation.operationName,
                message: 'Changes have been saved'
              });
            }
          }
        }

        return data;
      });
    }),

  retry: () =>
    new RetryLink({
      delay: {
        initial: 2000,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 10,
        retryIf: error => !!error
      }
    }),

  error: () =>
    onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        // eslint-disable-next-line no-restricted-syntax
        for (const { message, locations, path, extensions } of graphQLErrors) {
          if (extensions?.category === 'forbidden') {
            location.href = `/forbidden`;

            return;
          } else if (
            extensions?.category === 'authentication' ||
            !localStorage.uc_token
          ) {
            localStorage.clear();
            location.href = '/sign-in';
          } else if (
            extensions?.category === 'validation' &&
            operation.operationName === 'mediaWithFolders'
          ) {
            const { validations } = extensions;

            // eslint-disable-next-line no-restricted-syntax
            for (const fieldKey in validations) {
              // eslint-disable-next-line no-prototype-builtins
              if (validations.hasOwnProperty(fieldKey)) {
                const validationArr: { rule: string; placeholders: {} | [] }[] =
                  validations[fieldKey];

                validationArr.forEach(v => {
                  const placeholders = v.placeholders || {};

                  antMessage.error(
                    i18n.t(`errors:${v.rule}`, {
                      attribute: fieldKey,
                      ...placeholders
                    })
                  );
                });
              }
            }
          }

          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
      }

      if (networkError) {
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${networkError}`);

        if (typeof window !== 'undefined' && !window.navigator.onLine) {
          i18n.t('Sorry, your browser is offline.');
        } else {
          i18n.t('Some other network error occurred.');
        }
      }
    }),

  builder: () =>
    new HttpLink({
      uri: builder_uri,
      fetch
    }),

  file: () =>
    createUploadLink({
      uri: accounts_uri
    }),

  builderFile: () =>
    createUploadLink({
      fetch: (u, o) => customFetch(builder_uri, o),
      uri: builder_uri
    }),

  accounts: () =>
    new HttpLink({
      uri: accounts_uri,
      fetch
    }),

  commerce: () =>
    new HttpLink({
      uri: commerce_uri,
      fetch
    })
};

// eslint-disable-next-line no-shadow
export enum linkTypes {
  auth = 'auth',
  file = 'file',
  accounts = 'accounts',
  accountsV2 = 'accountsV2',
  commerce = 'commerce',
  error = 'error',
  retry = 'retry',
  builder = 'builder',
  builderFile = 'builderFile'
}

export const projectNameForRemoteHost = makeVar<string>('');

const findLink = () =>
  ApolloLink.split(
    op => op.getContext().urlType === linkTypes.builder,
    links.builder(),
    ApolloLink.split(
      op => op.getContext().urlType === linkTypes.builderFile,
      links.builderFile(),
      links.commerce(),
      ApolloLink.split(
        op => op.getContext().urlType === linkTypes.file,
        links.file(),
        ApolloLink.split(
          op => op.getContext().urlType === linkTypes.accounts,
          links.accounts()
        )
      )
    )
  );

export const link = from([
  links.namedLink(),
  links.auth(),
  links.error(),
  links.retry(),
  links.file(),
  findLink()
]);
