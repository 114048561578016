import React, { ReactElement, Component } from 'react';

interface Props {
  children: ReactElement;
}

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    /* eslint-disable-next-line no-console */
    console.log(error, errorInfo);
    this.setState({
      hasError: true
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <h1>Something went wrong :( </h1>;
    }

    return children;
  }
}
