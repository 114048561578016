import React from 'react';

import { Wrapper, Loader, Circle } from './styles';

const Loading = () => {
  const s = [0, 4, 8];

  return (
    <Wrapper>
      <Loader>
        {s.map(item => (
          <Circle key={item} duration={item} />
        ))}
      </Loader>
    </Wrapper>
  );
};

export default Loading;
