import { createGlobalStyle } from 'styled-components';

// @ts-ignore
import JakartaSansRegular from './assets/fonts/PlusJakartaSans-Regular.ttf';
// @ts-ignore
import JakartaSansMedium from './assets/fonts/PlusJakartaSans-Medium.ttf';
// @ts-ignore
import JakartaSansBold from './assets/fonts/PlusJakartaSans-Bold.ttf';

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-collapse {
  .ant-collapse-content {
    transition: opacity 600ms !important;
  }
}

.ant-layout-content {
  background-color: #f0f2f5 !important;

  > div {
    padding-bottom: 24px;
    border-radius: 4px;

    .ghostPageContainer {
      height: 1px;
    }
  }
}

.site-layout {
  padding: 24px;
}

.modal-close-button {
  .anticon-close {
    svg {
      font-size: 12px;
    }
  }
}

.ant-layout-has-sider {
  background-color: #fff !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#root {
    width: 100%;
    height: 100vh;
}

.ant-spin,
.ant-spin-spinning {
  width: 100%;
  height: 100%;
}

@font-face {
  font-weight: normal;
  font-family: 'JakartaPlus';
  font-style: normal;
  src: local('JakartaPlus'),
    url(${JakartaSansRegular}) format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: 'JakartaPlus';
  font-style: normal;
  src: local('JakartaPlus'),
    url(${JakartaSansMedium}) format('truetype');
}

@font-face {
  font-weight: bold;
  font-family: 'JakartaPlus';
  font-style: normal;
  src: local('JakartaPlus'),
    url(${JakartaSansBold}) format('truetype');
}

@font-face {
  font-weight: normal;
  font-family: 'ProximaNova';
  font-style: normal;
  src: local('ProximaNova'),
    url('./assets/fonts/regular.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'ProximaNova';
  font-style: normal;
  src: local('ProximaNova'), url('./assets/fonts/medium.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-family: 'ProximaNova';
  font-style: normal;
  src: local('ProximaNova'),
    url('./assets/fonts/regular.ttf') format('truetype');
}

@font-face {
  font-weight: bold;
  font-family: 'ProximaNova';
  font-style: normal;
  src: local('ProximaNova'), url('./assets/fonts/bold.ttf') format('truetype');
}

:root:root {
  --adm-font-family: 'JakartaPlus';
  --adm-color-primary: #4da789;
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.cursorPointer {
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.linkButton {
  .anticon {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    /* stylelint-disable-next-line */
    :global .anticon {
      opacity: 1;
    }
  }
}

.primary-button {
  height: 40px;
  background: #F0F0F0;
  border-radius: 12px;
  border: none;
}

.deleteModal {
  .ant-btn-dangerous {
    background-color: #FF4D4F;
    color: #fff;
  }
}

.delete-modal {
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 5px;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 10px 16px 26px;
  }
}

.ant-dropdown-menu-item-disabled {
  cursor: default !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400 !important;
}

.textEllipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-tooltip-inner {
  color: #595959 !important;
  background-color: #fff;
}

.ant-tooltip {
  white-space: pre;
}

tbody {
  tr {
    .columnActions {
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      .columnActions {
        opacity: 1;
      }
    }
  }
}

.primaryColor {
  color: #4da789;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SpaceItemCenter div[class*='space-item'] {
  display: flex;
}

.sectionIcon:not(:empty) {
  color: #4da789;
  font-size: 19px;
}

.componentBackground {
  background: #fff;
}

.arrowDropdownStyle {
  .anticon {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .anticon {
      opacity: 1;
    }
  }
}

.visibleIcon {
  color: #4da789;
  font-size: 28px;
}

.ant-pro-table-alert {
  position: absolute;
  top: calc(24px / 2);
  right: 24px;
  left: 24px;
}

.ant-list-item {
  padding: 12px 12px !important;
}

.ant-table-cell .ant-btn.ant-btn-link {
  padding: 0 4px !important;
}

.ant-btn + .ant-btn {
  margin-left: 10px;
}
`;

export default GlobalStyle;
