const AUTH_TOKEN_KEY = 'uc_token';
const USER_INFO_KEY = 'user_info';
const PROJECT_ID_KEY = 'project_id';
const BUSINESS_ID_KEY = 'business_id';
const TEMPLATE_ID_KEY = 'template_id';

export const LocalStorage = {
  getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  },
  setAuthToken(token: string) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  },
  getUserInfo() {
    return localStorage.getItem(USER_INFO_KEY);
  },
  setUserInfo(userInfo: string) {
    localStorage.setItem(USER_INFO_KEY, userInfo);
  },
  getProjectId() {
    return localStorage.getItem(PROJECT_ID_KEY);
  },
  setProjectId(projectId: string) {
    localStorage.setItem(PROJECT_ID_KEY, projectId);
  },
  getBusinessId() {
    return localStorage.getItem(BUSINESS_ID_KEY);
  },
  setBusinessId(businessId: string) {
    localStorage.setItem(BUSINESS_ID_KEY, businessId);
  },
  getTemplateId() {
    return localStorage.getItem(TEMPLATE_ID_KEY);
  },
  setTemplateId(templateId: string) {
    localStorage.setItem(TEMPLATE_ID_KEY, templateId);
  },
  clear() {
    localStorage.clear();
  }
};
