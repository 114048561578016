import { Checkbox, CheckboxProps } from 'antd';

import UcCheckboxGroup from '../CheckboxGroup';

const BaseCheckbox = (props: CheckboxProps) => <Checkbox {...props} />;

const UcCheckbox = Object.assign(Checkbox, BaseCheckbox, {
  Group: UcCheckboxGroup
});

export default UcCheckbox;
