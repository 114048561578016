/* eslint-disable no-underscore-dangle */
import {
  InMemoryCache,
  FieldFunctionOptions,
  TypePolicy,
  makeVar
} from '@apollo/client';
import { uniqBy } from 'lodash';

import { Workspace, WorkspacePaginationInfo, Accounts } from './types';

const areCssVariablesGeneratedVar = makeVar(false);
const designPresetIdVar = makeVar<null | string>(null);
export const coreConfigs = makeVar<any>({});
export const isMobileDevice = makeVar<boolean>(false);
export const currentUser = makeVar<any>(null);
export const workspacesListVar = makeVar<[] | Workspace[]>([]);
export const currentWorkspaceVar = makeVar<Workspace | null>(null);
export const createWorkspaceVar = makeVar<boolean>(false);
export const workspacePaginatorInfo = makeVar<WorkspacePaginationInfo>({
  hasMorePages: false,
  perPage: 1
});
export const accountsDataVar = makeVar<[] | Accounts[]>([]);

const merge = (
  existing = { data: [] },
  incoming: { data: [] },
  args?: FieldFunctionOptions
) => {
  if (args?.variables?.onlyIncoming) {
    return incoming;
  }

  return {
    ...incoming,
    data: uniqBy([...existing.data, ...incoming.data], '__ref')
  };
};

const mediaKeyFields: TypePolicy = {
  keyFields: incoming => `Media:${incoming.id}`
};

export const cache = new InMemoryCache({
  possibleTypes: {
    MediaInterface: ['Image', 'Video', 'Audio', 'Document', 'Folder']
  },
  typePolicies: {
    Configuration: {
      fields: {
        languageInfo: {
          keyArgs: []
        }
      }
    },
    Setting: {
      keyFields: incoming => `${incoming.__typename}:${incoming.name}`
    },
    LanguageInfo: {
      keyFields: incoming => `${incoming.__typename}:${incoming.code}`
    },
    Page: {
      fields: {
        children: {
          read(children) {
            return children && children.length ? children : null;
          },
          merge(existing, incoming) {
            return incoming;
          }
        }
      },
      keyFields: incoming => {
        const languageCode = localStorage.getItem('currentLanguageCode');

        return `${incoming.__typename}:${incoming.id}${
          languageCode ? `:${languageCode}` : ''
        }`;
      }
    },
    MenuItem: {
      fields: {
        children: {
          merge(existing, incoming) {
            return incoming?.length ? incoming : null;
          }
        }
      }
    },
    Query: {
      fields: {
        configurations: {
          merge(existing = {}, incoming = {}) {
            return { ...existing, ...incoming };
          }
        },
        mediaWithFolders: {
          keyArgs: ['search', 'type', 'parentId', 'orderBy'],
          merge(
            existing = { data: [] },
            incoming: { data: [] },
            { variables }
          ) {
            return variables?.fetchMore ? merge(existing, incoming) : incoming;
          }
        },
        unsplashImages: {
          keyArgs: ['search'],
          merge
        },
        folders: {
          keyArgs: ['first', 'type'],
          merge
        },
        customFonts: {
          keyArgs: [],
          merge
        },
        collections: {
          keyArgs: ['search'],
          merge
        },
        pages: {
          keyArgs: ['search'],
          merge
        },
        areCssVariablesGenerated() {
          return areCssVariablesGeneratedVar();
        }
      }
    },
    Grid: {
      keyFields: incoming =>
        `Grid:${(incoming.designPreset as { id: string })?.id}:${incoming.type}`
    },
    PresetFont: {
      keyFields: incoming =>
        `PresetFont:${(incoming.designPreset as { id: string })?.id}:${
          incoming.type
        }:${incoming.family}`
    },
    CustomFont: {
      keyFields: incoming => `CustomFont:${incoming.type}:${incoming.family}`
    },
    GoogleFont: {
      keyFields: incoming => `GoogleFont:${incoming.family}:${incoming.type}`
    },
    FontVariant: {
      keyFields: false
    },
    Image: mediaKeyFields,
    Folder: mediaKeyFields,
    Video: mediaKeyFields,
    Audio: mediaKeyFields,
    Document: {
      keyFields: incoming => `Media:${incoming.id}`
    },
    FormFieldWidgetBinding: {
      keyFields: incoming =>
        `FormFieldWidgetBinding:${incoming.hash}:${incoming.targetProperty}`
    },
    CssVariable: {
      keyFields: incoming =>
        `CssVariable:${designPresetIdVar() as string}:${
          (incoming.uiElement as { id: string })?.id || 'General'
        }:${incoming.name}`
    },
    UiElement: {
      keyFields: incoming =>
        `${incoming.__typename}:${designPresetIdVar() as string}:${
          incoming.id
        }`,
      fields: {
        params: {
          read(incoming) {
            return typeof incoming === 'string'
              ? JSON.parse(incoming)
              : incoming;
          }
        }
      }
    },
    DefaultUiElement: {
      fields: {
        params: {
          read(incoming) {
            return typeof incoming === 'string'
              ? JSON.parse(incoming)
              : incoming;
          }
        }
      }
    }
  }
});
