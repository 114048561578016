import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Space, Layout, Menu, message, Image } from 'antd';
import type { MenuProps as AntdMenuProps } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { currentUser, currentWorkspaceVar } from 'apollo/cache';
import axiosApi from 'axiosApi';
import UcraftLogo from 'assets/svg/ucraftLogo.svg';
import { navigateToWorkspaceURL } from 'utils';
import { useAuthState } from 'providers/AuthProvider';

import ProjectsNavBar from 'components/ProjectsNavBar';
import { Dropdown } from 'components/basic';
import { MenuProps } from './menuTypes';
import {
  StyledHeaderWrapper,
  StyledHeaderContent,
  StyledUserWrapper,
  StyledLogoWrapper
} from './style';
import NavBar from 'components/NavBar';

type MenuItem = Required<AntdMenuProps>['items'][number];

const { Header } = Layout;

interface Props {
  user: any;
  isMobile: boolean;
  menus: MenuProps[];
  currentProject: string;
  children?: React.ReactElement;
  topRightMenu?: MenuItem[];
}

const HeaderComponent: FC<Props> = ({
  children,
  isMobile,
  menus,
  currentProject,
  topRightMenu
}) => {
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const [visibleProjects, setVisibleProjects] = useState<boolean>(false);

  const { setAuthenticated } = useAuthState();

  const currUser = useReactiveVar(currentUser);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const currentWorkspace = useReactiveVar(currentWorkspaceVar);

  const toggleMenu = (data: boolean) => {
    setVisibleMenu(data);

    if (visibleProjects && data) {
      setVisibleProjects(false);
    }
  };

  const toggleProjects = (data: boolean) => {
    setVisibleProjects(data);

    if (visibleMenu && data) {
      setVisibleMenu(false);
    }
  };

  const logOutHandler = async () => {
    try {
      await axiosApi.post('/api/logout');
      localStorage.clear();
      setAuthenticated(false);
      location.href = '/sign-in';
    } catch (e) {
      message.error(t('error'));
    }
  };

  const items: MenuItem[] = [
    {
      label: <span>{t('accountSettings')}</span>,
      key: '1',
      onClick: () =>
        navigateToWorkspaceURL('/my-accounts', currentWorkspace, navigate)
    },
    {
      label: <span>{t('logout')}</span>,
      key: '2',
      onClick: logOutHandler
    }
  ];

  const menu = <Menu items={topRightMenu ?? items} />;

  return (
    <StyledHeaderWrapper>
      <Header className="header">
        {children}
        {isMobile && (
          <NavBar menus={menus} visible={visibleMenu} setVisible={toggleMenu} />
        )}
        {isMobile && (
          <ProjectsNavBar
            currentProject={currentProject}
            visible={visibleProjects}
            setVisible={toggleProjects}
          />
        )}
        <StyledHeaderContent>
          <StyledLogoWrapper>
            <Image
              preview={false}
              onClick={() =>
                navigateToWorkspaceURL(`/projects`, currentWorkspace, navigate)
              }
              src={UcraftLogo}
            />
          </StyledLogoWrapper>
          <Space size={0}>
            <StyledUserWrapper>
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                trigger={['click']}
                className="user-dropdown"
              >
                <Space direction="horizontal">
                  <Avatar
                    size={34}
                    icon={!currUser?.avatarUrl && <UserOutlined />}
                    src={currUser?.avatarUrl}
                  />
                  <DownOutlined style={{ fontSize: '10px' }} />
                </Space>
              </Dropdown>
            </StyledUserWrapper>
          </Space>
        </StyledHeaderContent>
      </Header>
    </StyledHeaderWrapper>
  );
};

export default HeaderComponent;
