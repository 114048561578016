import { FC } from 'react';
import { Collapse, CollapseProps } from 'antd';

import UcPanel, { UcPanelProps } from './panel';

type UcCollapseOptions = Array<UcPanelProps>;

interface Props extends CollapseProps {
  options?: UcCollapseOptions;
}

const BaseCollapse: FC<Props> = props => {
  const { options, children, ...rest } = props;

  return (
    <Collapse {...rest}>
      {options?.map(option => (
        <UcPanel {...option} key={option.key} />
      ))}
      {children}
    </Collapse>
  );
};

const UcCollapse = Object.assign(BaseCollapse, Collapse, { Panel: UcPanel });

export default UcCollapse;
