import lazyImport from 'utils/retryLazyImport';

// Start page
export const HomePage = lazyImport(() => import('pages/Home'));

// Projects components
export const ProjectsList = lazyImport(() => import('pages/projects'));

export const AddProject = lazyImport(
  () => import('pages/projects/components/AddProject')
);
export const EditProject = lazyImport(
  () => import('pages/projects/components/EditProject')
);

// Clients pages
export const ClientsList = lazyImport(
  () => import('pages/clients/pages/ClientsList')
);
export const ClientDetails = lazyImport(
  () => import('pages/clients/pages/ClientDetails')
);

// Apps pages
export const AppsList = lazyImport(() => import('pages/apps/pages/AppsList'));
export const AddApp = lazyImport(() => import('pages/apps/pages/AddApp'));

//Apps component
export const AppComponents = lazyImport(
  () => import('pages/apps/pages/AppsComponent')
);

// Teams pages
export const TeamsList = lazyImport(
  () => import('pages/teams/pages/TeamsList')
);

export const AddEditMember = lazyImport(
  () => import('pages/teams/pages/AddEditMember')
);

// Settings pages
export const SettingsApp = lazyImport(() => import('pages/settings'));

// My Accounts
export const MyAccountsApp = lazyImport(() => import('pages/myAccounts'));

// My Workspaces
export const MyWorkspaces = lazyImport(() => import('pages/workspaces'));

// Templates pages
export const TemplatesList = lazyImport(
  () => import('pages/templates/pages/TemplatesList')
);

export const AddTemplate = lazyImport(
  () => import('pages/templates/pages/AddTemplate')
);

export const EditTemplate = lazyImport(
  () => import('pages/templates/pages/EditTemplate')
);

export const ForbiddenPage = lazyImport(() => import('pages/Forbidden'));

export const SignInPage = lazyImport(() => import('pages/Auth/SignIn'));
export const SignUpPage = lazyImport(() => import('pages/Auth/SignUp'));
