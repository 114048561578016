import { gql } from '@apollo/client';

export const updateProfileMutation = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      firstName
      lastName
      language
      receiveNewsletter
      avatarUrl
    }
  }
`;

export const createBillingDetailsMutation = gql`
  mutation createBillingDetails($input: CreateBillingDetailsInput!) {
    createBillingDetails(input: $input) {
      billingDetails {
        fullName
        country
        city
        street
        zipCode
        phoneNumber
        phoneCode
        state
      }
    }
  }
`;

export const updateBillingDetailsMutation = gql`
  mutation updateBillingDetails($input: UpdateBillingDetailsInput!) {
    updateBillingDetails(input: $input) {
      billingDetails {
        fullName
        country
        city
        street
        zipCode
        phoneNumber
        phoneCode
        state
      }
    }
  }
`;

export const updatePasswordMutation = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input)
  }
`;

export const deleteUserMutation = gql`
  mutation deleteUser($input: ID!) {
    deleteUser(id: $input)
  }
`;

export const deleteAccountMutation = gql`
  mutation deleteAccount($input: DeleteAccountInput!) {
    deleteAccount(input: $input)
  }
`;

export const sendDeleteAccountVerificationEmailMutation = gql`
  mutation verifyAccountDeletion {
    verifyAccountDeletion
  }
`;

export const getProjectsForAccountDeleteQuery = gql`
  query projects($first: Int!, $page: Int, $userId: ID!) {
    projects(first: $first, page: $page) {
      data {
        id
        name
        status
        expirationDate
        userRoles(userId: $userId) {
          id
          name
        }
      }
      paginatorInfo {
        hasMorePages
        total
      }
    }
  }
`;

export const getAccountDeleteReasonsQuery = gql`
  query accountDeletionReasons {
    accountDeletionReasons {
      code
      description
    }
  }
`;

export const removeAvatarMutation = gql`
  mutation removeAvatar {
    removeAvatar
  }
`;
