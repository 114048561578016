import React, { useMemo, ReactChild, FC } from 'react';
import { Drawer } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

import { MenuProps } from '../GlobalHeader/menuTypes';
import SiderMenu from '../GlobalHeader/SiderMenu';
import { StyledNavbarWrapper } from './style';

interface Props {
  children?: ReactChild;
  menus: MenuProps[];
  visible: boolean;
  setVisible: (data: boolean) => void;
}

const NavBar: FC<Props> = ({ children, menus, visible, setVisible }) => {
  const isSmall = useMemo(() => window.innerWidth < 379, [window.innerWidth]);

  return (
    <StyledNavbarWrapper>
      {!visible ? (
        <MenuOutlined
          style={{ fontSize: '20px' }}
          onClick={() => setVisible(true)}
        />
      ) : (
        <CloseOutlined
          style={{ fontSize: '20px' }}
          onClick={() => setVisible(false)}
        />
      )}
      <Drawer
        width={isSmall ? 320 : 378}
        title={children}
        placement="left"
        headerStyle={{
          padding: '16px 8px',
          display: 'inline-flex',
          justifyContent: 'flex-end'
        }}
        bodyStyle={{ padding: '11px 1px 24px 0' }}
        onClose={() => setVisible(false)}
        open={visible}
      >
        <SiderMenu menus={menus} setVisible={setVisible} />
      </Drawer>
    </StyledNavbarWrapper>
  );
};

export default NavBar;
