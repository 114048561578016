import { ActionTypes, State, Action } from './types';
import initialState from './initialState';

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_FETCHING:
      return {
        ...state,
        user: { ...state.user, isFetching: action.isFetching }
      };
    case ActionTypes.SET_USER_DATA:
      return {
        ...state,
        user: { data: action.data, isFetching: false }
      };
    case ActionTypes.USER_LOGOUT:
      return { ...initialState };
    case ActionTypes.UPDATE_USER_DATA:
      const params = {
        params: {
          language: action?.payload?.language,
          receiveNewsletter: action?.payload?.receiveNewsletter
        }
      };

      const user = {
        avatarUrl: action?.payload?.avatarUrl,
        firstName: action?.payload?.firstName,
        lastName: action?.payload?.lastName
      };

      const updatedData = Object.assign({}, state?.user?.data, user, params);

      return {
        ...state,
        user: { data: updatedData, isFetching: false }
      };
    case ActionTypes.UPDATE_USER_BILLING_DATA:
      const billingData = { billingDetails: action.payload };
      const updatedBillingData = Object.assign(
        {},
        state?.user?.data,
        billingData
      );

      return {
        ...state,
        user: {
          data: updatedBillingData,
          isFetching: false
        }
      };
    case ActionTypes.REMOVE_USER_AVATAR:
      const userInfo = Object.assign({}, state.user.data, { avatarUrl: null });

      return {
        ...state,
        user: {
          ...state.user,
          data: userInfo
        }
      };
    default:
      return state;
  }
};

export default reducer;
