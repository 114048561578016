import { useLocation } from 'react-router-dom';

const useMenuActiveUrl = (paths: string[]) => {
  const { pathname } = useLocation();
  const pathnames = pathname.split('/');

  return pathnames.filter((p: string) => paths.includes(p)).pop();
};

export default useMenuActiveUrl;
