import React, { FC } from 'react';
import { Select } from 'antd';
import { OptionProps } from 'antd/es/mentions';

const { Option } = Select;

type BaseSelectOptionProps = OptionProps;

const BaseSelectOption: FC<BaseSelectOptionProps> = props => (
  <Option {...props} />
);

const UcSelectOption = Object.assign(Option, BaseSelectOption);

export default UcSelectOption;
