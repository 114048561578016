import { ReactNode, FC } from 'react';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    whitelist: ['en'],
    ns: ['common', 'errors', 'accounts'],
    defaultNS: 'common'
  });

interface Props {
  children: ReactNode;
}

const MyI18nextProvider: FC<Props> = ({ children }) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default MyI18nextProvider;
