import React, {
  useState,
  useEffect,
  ReactNode,
  FC,
  createContext
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Api from 'axiosApi';
import { message } from 'antd';

import { LocalStorage } from 'utils/localStorageServices';

export interface AuthContextState {
  isAuthenticated: boolean;
  setAuthenticated: (isAuthenticated: boolean) => void;
}

const AuthContext = createContext<AuthContextState | undefined>(undefined);

interface Props {
  children: ReactNode;
}

const AuthProvider: FC<Props> = ({ children }) => {
  const workSpaceName = useLocation().pathname.split('/')[1];
  const initialState = localStorage.getItem('isAuthenticated') || '0';
  const [isAuthenticated, setAuthenticated] = useState(initialState !== '0');

  const navigate = useNavigate();
  localStorage.setItem('workSpaceName', workSpaceName || '');

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has('nonce')) {
      const fetchData = async () => {
        try {
          const res = await Api.get('/api/access-token', {
            params: { nonce: params.get('nonce') }
          });

          LocalStorage.setAuthToken(res.data.accessToken);
          setAuthenticated(true);
          localStorage.setItem('isAuthenticated', '1');
        } catch (err: any) {
          message.error(err?.response?.data?.message);
        } finally {
          navigate(location.pathname);
        }
      };

      fetchData();
    }
  }, []);

  const handleSetAuthentication = (isAuthenticated: boolean) => {
    setAuthenticated(isAuthenticated);
    localStorage.setItem('isAuthenticated', isAuthenticated ? '1' : '0');
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, setAuthenticated: handleSetAuthentication }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export function useAuthState() {
  const context = React.useContext(AuthContext);

  if (typeof context === 'undefined') {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}
