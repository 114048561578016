import React, { FC } from 'react';
import { Dropdown, DropDownProps } from 'antd';

type Props = DropDownProps;

const BaseDropdown: FC<Props> = props => <Dropdown {...props} />;

const UcDropdown = Object.assign(Dropdown, BaseDropdown);

export default UcDropdown;
