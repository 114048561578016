/* eslint-disable prettier/prettier */
import React, { useMemo, FC } from 'react';
import { Menu } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import type { MenuProps as TMenuProps } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { currentWorkspaceVar } from 'apollo/cache';
import { navigateToWorkspaceURL } from 'utils';

import useMenuActiveUrl from 'hooks/useMenuActiveUrl';
import { MenuProps } from './menuTypes';
import { StyledMenuWrapper } from './style';

type MenuItem = Required<TMenuProps>['items'][number];

interface Props {
  menus: MenuProps[];
  setVisible?: (data: boolean) => void;
  defaultSelected?: string;
}

const SiderMenu: FC<Props> = ({ menus, defaultSelected }) => {
  const [openKeys, setOpenKeys] = React.useState<React.Key[]>([]);
  const navigate = useNavigate();

  const currentWorkspace = useReactiveVar(currentWorkspaceVar);

  const paths = useMemo(
    () =>
      menus
        .map(m => (m.subMenu ? m.subMenu : m))
        .flat()
        .map(m => m.key) || [],
    [menus]
  );

  const menuActiveUrl = useMenuActiveUrl(paths);

  const onOpenChange = (keys: React.Key[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const getItem = (
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label
    } as MenuItem;
  };

  const items: MenuItem[] = menus.map(e =>
    e.subMenu && e.key !== 'settings'
      ? getItem(
          e.title,
          e.key,
          e.icon,
          e.subMenu.map(item =>
            getItem(
              <Link
                to={`/${item?.url}`}
                onClick={() =>
                  navigateToWorkspaceURL(
                    `/${item?.url}`,
                    currentWorkspace,
                    navigate
                  )
                }
              >
                {item.subTitle}
              </Link>,
              item.key,
              null
            )
          )
        )
      : getItem(
          <Link
            className="linkItemsMenu"
            to={`/${e?.url}`}
            onClick={() =>
              navigateToWorkspaceURL(`/${e?.url}`, currentWorkspace, navigate)
            }
          >
            {e.title}
          </Link>,
          e.key,
          e.icon
        )
  );

  return (
    <StyledMenuWrapper>
      <Menu
        onOpenChange={onOpenChange}
        openKeys={[...openKeys].map(String)}
        style={{ border: 'none' }}
        defaultSelectedKeys={['projects']}
        selectedKeys={[defaultSelected || menuActiveUrl || 'projects']}
        items={items}
      />
    </StyledMenuWrapper>
  );
};

export default SiderMenu;
