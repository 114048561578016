import styled from 'styled-components';

interface Props {
  duration: number;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
`;

export const Loader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div<Props>`
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #235df4;
  border-radius: 50%;
  opacity: 0;
  animation: animate 2s linear infinite;
  animation-delay: calc(${props => props.duration} * 0.1s);

  @keyframes animate {
    0% {
      transform: scale(0);
      opacity: 0.8;
    }

    60% {
      opacity: 0;
    }

    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
`;
