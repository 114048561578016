import React, { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

type Props = ButtonProps;

const BaseButton: FC<Props> = props => <Button {...props} />;

const UcButton = Object.assign(Button, BaseButton);

export default UcButton;
