import { Input, InputProps } from 'antd';

import UcSearch from '../Search';

const BaseInput = (props: InputProps) => (
  <Input autoComplete="off" {...props} />
);

const UcInput = Object.assign(Input, BaseInput, {
  Search: UcSearch
});

export default UcInput;
